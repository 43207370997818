import React, { useState } from "react";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import { HiOutlineMail } from "react-icons/hi";
import { BiLock } from "react-icons/bi";
import { collection, setDoc, doc, serverTimestamp, getDoc } from "firebase/firestore";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { FcGoogle } from "react-icons/fc";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";

function Signup() {
  const SignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        const userRef = doc(db, "users", user?.uid);
        await setDoc(
          userRef,
          {
            displayName: user?.displayName,
            email: user?.email,
            uid: user?.uid,
            photoUrl: user?.photoURL,
            createdAt:serverTimestamp(),
            plan:'InActive',
            subscriptionStatus:'InActive'
          },
          { merge: true }
        );

        const statRef = doc(db, 'analytics', 'subscriptions');

        let stats=await getDoc(statRef)
  
        stats=stats?.data()

        let total_users=stats.total_users +1
        await setDoc(
          statRef,
          {
            total_users:total_users
          },
          { merge: true }
        );


        // console.log("saved");
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);

        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const user = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      await updateProfile(auth.currentUser, {
        displayName: `${data?.first_name} ${data?.last_name}`,
      });

      const docRef = await setDoc(doc(db, "users", user?.user?.uid), {
        displayName: `${data?.first_name} ${data?.last_name}`,
        email: data.email,
        uid: user?.user?.uid,
        createdAt:serverTimestamp(),
        plan:'InActive',
            subscriptionStatus:'InActive'
      });


      const statRef = doc(db, 'analytics', 'subscriptions');

        let stats=await getDoc(statRef)
  
        stats=stats?.data()

        let total_users=stats.total_users +1
        await setDoc(
          statRef,
          {
            total_users:total_users
          },
          { merge: true }
        );

      //   console.log("--->>>DocRef", docRef);

      // await sendPasswordResetEmail(auth, data?.email);
      await sendEmailVerification(auth.currentUser);
      await signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
      reset();
      navigate("/login");
      toast.success("Verification Email sent");
    } catch (error) {
      console.log(error);

      if (error.code === "auth/email-already-in-use") {
        toast.error("Email Already Exists");
      } else {
        toast.error("Something Went Wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
       flex w-screen h-screen justify-center
     
      bg-gradient-to-br from-secondary  to-primary

    overflow-y-scroll
        
      
        "
    >
      <div className="bg-white h-screen w-full flex bg-opacity-80   overflow-y-scroll   justify-center z-20">
        <div
          className="
          w-[95%]
        sm:w-[70%]
          md:w-[50%]
         lg:w-[40%]
            xl:w-[30%]
            mx-2
            rounded-md
            px-4
            py-8
            flex
            flex-col 
            items-center
            sm:rounded-lg
            sm:px-10
         mb-20
            "
        >
          <img src="/speechimologo.png" className="w-56  " alt="logo" />
          {/* <div className="text-xl  mb-10">Get your bag 💰💸😤</div> */}

          <form className="space-y-6 w-full">
            <Input
              id="first_name"
              label="First Name"
              placeholder="First Name"
              required
              LeftIcon={
                () => null
                // <HiOutlineMail className="text-gray-400 text-2xl" />
              }
              register={register}
              errors={errors}
            />
            <Input
              id="last_name"
              label="Last Name"
              placeholder="Last Name"
              required
              LeftIcon={
                () => null
                // <HiOutlineMail className="text-gray-400 text-2xl" />
              }
              register={register}
              errors={errors}
            />
            <Input
              id="email"
              label="Email"
              placeholder="Email"
              required
              LeftIcon={() => (
                <HiOutlineMail className="text-gray-400 text-2xl" />
              )}
              register={register}
              errors={errors}
            />

            <Input
              id="password"
              label="Password"
              type="password"
              placeholder="Password"
              required
              register={register}
              errors={errors}
              LeftIcon={() => <BiLock className="text-gray-400 text-2xl" />}
            />
            <div className="w-full mx-auto">
              <Button
                disabled={isLoading}
                label="Sign Up"
                onClick={handleSubmit(onSubmit)}
              />
            </div>

            <div className="flex flex-row items-center justify-center">
              <p className="text-gray-900">Already have an account?</p>
              <Link
                to="/login"
                className="text-primary text-xl underline cursor-pointer ml-1"
              >
                Sign In
              </Link>
            </div>
            <div className="w-full mx-auto ">
              <div
                onClick={SignIn}
                className="bg-white px-4 py-3 justify-center cursor-pointer rounded-lg flex flex-row items-center gap-2"
              >
                <FcGoogle className="text-xl" />
                <h1 className="text-secondary font-semibold">
                  Continue with Google
                </h1>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
