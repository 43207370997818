// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBRVkRT29DpriCgE3i3WUFm5OecMWMwbwk",
    authDomain: "speechimo-54fc0.firebaseapp.com",
    projectId: "speechimo-54fc0",
    storageBucket: "speechimo-54fc0.appspot.com",
    messagingSenderId: "652583839041",
    appId: "1:652583839041:web:1ba87cdd3eb062af0dadc0",
    measurementId: "G-0DYGEBD1QN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
const storage = getStorage(app);
const analytics = getAnalytics(app);


export { auth, db, storage }