import React from "react";
import PreviewCallComponent from "./previewCallComponent";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function UseCases() {
  const navigate = useNavigate();
  const useCasesData = {
    usecases: [
      {
        title: "Videos",
        desc: `From YouTube tutorials to TikTok trends, our Al voice generator adds
        the perfect vocal touch to your video content. Create, captivate,
        and connect with audiences with voices that are as dynamic as your visuals.`,
        buttonTitle: "Capture Audiences on Every Platform",
        buttonDesc: `Elevate Your Video Content`,
      },
      {
        title: "E-Learnings",
        desc: `Make learning more effective and enjoyable with clear, engaging narration that simplifies complex concepts and keeps students focused. Ensure your students never experience a dull moment again, with narration that enlivens each lesson and holds their attention.`,
        buttonTitle: "Engaging Education",
        buttonDesc: `Voice of Learning`,
      },
      {
        title: "Audiobooks",
        desc: `Turn any story into an auditory journey, offering listeners a captivating escape into the world of your audiobooks. Our narration brings each character and scene to life, making every listen an unforgettable experience.`,
        buttonTitle: "Immerse Your Listeners",
        buttonDesc: `Audible Adventures`,
      },
      {
        title: "Podcasts",
        desc: `Create podcasts that resonate deeply with your audience, using voices that add depth and emotion to every word. Transform your ideas into engaging audio experiences that captivate listeners with every episode.`,
        buttonTitle: "Podcast with Personality",
        buttonDesc: `Voice Your Vision`,
      },
    ],
    benefits: [
      `✅  Create lifelike human voices for videos, presentations, and more.`,
      `✅  Generate professional-grade voiceovers in seconds.`,
      `✅  Save money by eliminating the need for expensive voice-over artists.`,
      `✅  Streamline content creation with efficient voice generation.`,
      `✅  Enhance productivity and efficiency in your projects.`,
    ],
  };

  return (
    <div className="w-full bg-white flex items-center justify-center">
      <div className=" max-w-[1460px] flex flex-col items-center py-10 pb-20 px-4 md:px-0 bg-white">
        <div className="flex flex-col items-center px-1">
          <h1 className="text-gray-800 text-3xl font-semibold text-center">
            Not just another Text to Speech Tool
          </h1>
          <p className="text-gray-800 mt-4 font-light text-lg text-center md:px-4s">
            With Speechimo, you can instantly create the most high-quality,
            human-sounding voiceovers.{" "}
          </p>
        </div>

        <div className="flex mx-[2%] md:mx-[8%] lg:mx-[15%] flex-col gap-7">
          <p className="text-gray-700 my-10 font-light text-justify ">
            Elevate your content across multiple platforms with our premium
            voices, crafted to deliver a human-like audio experience that
            enhances videos, audiobooks, podcasts, and beyond.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-16 ">
            {useCasesData.usecases.map((data) => (
              <PreviewCallComponent data={data} />
            ))}
          </div>

          <div className=" flex flex-col items-center justify-center my-16 px-1 ">
            <div className="bg-skygray p-5 rounded-lg shadow-lg">
              {useCasesData.benefits.map((benefit) => (
                <p className="text-gray-500 my-4 font-semibold whitespace-pre-wrap">
                  {benefit}
                </p>
              ))}
            </div>
          </div>

          {/* <div className="flex bg-transparent w-[245px] ml-[5px] self-center items-center">
            <div
              onClick={() => navigate("/signup")}
              style={{
                boxShadow: " -1px 1px 11px -5px rgba(0,0,0,0.65)",
              }}
              className="cursor-pointer bg-secondary group py-7 px-9 rounded-full  flex flex-row items-center justify-between"
            >
              <h1 className="text-white text-xl font-bold">Start My Trial</h1>
              <FiArrowRight className="text-white duration-300 ml-[5px] group-hover:ml-[15px] text-xl font-bold" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default UseCases;
