import React, { useState, useEffect } from "react";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

const ffmpeg = createFFmpeg({
  //   corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
  log: true,
});

const AudioRecorder = ({ onRecordingComplete }) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState([]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) =>
        setAudioData((currentData) => [...currentData, e.data]);
      setMediaRecorder(recorder);
    });
  }, []);

  const startRecording = () => {
    setAudioData([]);
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = async () => {
    mediaRecorder.stop();
    setIsRecording(false);
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    const audioBlob = new Blob(audioData, { type: "audio/ogg" }); // The type might be different based on the browser
    convertToWav(audioBlob);
  };

  const convertToWav = async (blob) => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    ffmpeg.FS("writeFile", "recording.ogg", await fetchFile(blob));
    await ffmpeg.run(
      "-i",
      "recording.ogg",
      "-ar",
      "16000",
      "-ac",
      "1",
      "output.wav"
    );
    const data = ffmpeg.FS("readFile", "output.wav");
    console.log(data);
    const audioBlob = new Blob([data.buffer], { type: "audio/wav" });
    onRecordingComplete(audioBlob);
  };

  return (
    <div>
      <button onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
    </div>
  );
};

export default AudioRecorder;
