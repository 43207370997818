import React from "react";
import Navbar from "../../components/navbar";
import { BsArrowRight } from "react-icons/bs";
import ReviewCard from "../../components/landingpage/reviewCard";
import Reviews from "../../components/landingpage/Reviews";
import UseCases from "../../components/landingpage/useCases";
import Benefits from "../../components/landingpage/benefits";
import Faqs from "../../components/landingpage/faqs";
import Footer from "../../components/landingpage/footer";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Navbar2 from "../../components/navbar2";

function Landing2() {
  const navigate = useNavigate();
  return (
    <div className="w-screen overflow-hidden">
      <div className=" bg-skygray ">
        <div
          style={{ height: `h-screen` }}
          className="bg-gradient-to-br from-secondary to-primary relative flex flex-col "
        >
          <Navbar2 />
          <div className=" grid grid-cols-1 lg:grid-cols-2  mt-[50px] px-2 lg:px-6">
            <div className=" flex flex-col items-start gap-4 ml-2 lg:ml-14 ">
              <h1 className="text-white text-[28px] lg:text-[40px] font-[800] md:w-[55%]  lg:w-[70%] tracking-wider">
                {/* Introducing the Ultimate Text to Speech Solution! */}
                Bring Your Words to Life with Unmatched Simplicity!
              </h1>
              <p className="text-gray-300 w-[90%] md:w-[55%] ">
                Ditch expensive voiceovers! Our tool effortlessly turns your
                text into awesome audio without breaking the bank.
                <br />
                <br />
                <span className="font-bold">
                  Join the Waitlist Now !
                </span>
              </p>
              <form className="w-[90%] sm:w-[60%]" action="https://submit-form.com/NnCKp6rb4" method="POST">
                <div className="form-group">
                  <div className="form-row">
                    <div className="col">
                      <input type="text" name="name" className="form-control    p-2
            mt-2 
            flex 
            flex-row
             items-center
             
        form-input
        
        w-full
        rounded-md
        
        py-3
        text-lightblack
        shadow-sm
       
        bg-skygray
 
        placeholder:text-gray-400
        border-[1px]" placeholder="Full Name" required/>
                    </div>
                    <div className="col">
                      <input type="email" name="email" className="form-control    p-2
            mt-2 
            flex 
            flex-row
             items-center
             
        form-input
        
        w-full
        rounded-md
        
        py-3
        text-lightblack
        shadow-sm
       
        bg-skygray
 
        placeholder:text-gray-400
        border-[1px]" placeholder="Email Address" required/>
                    </div>
                  </div>
                </div>
    
                <button type="submit" className="mt-3 relative
  disabled:opacity-70
  disabled:cursor-not-allowed
  rounded-lg
  hover:opacity-80
  transition
  w-full
  bg-white
  py-3
  text-primary
  ">Join Waitlist</button>
              </form>
            </div>
            <div
              // style={{
              //   boxShadow: " -1px 1px 11px -5px rgba(0,0,0,0.65)",
              // }}
              className=" mt-5 lg:mt-0  lg:mr-20 xl:-ml-32 rounded-lg "
            >
              <video
                className="w-full rounded-lg"
                loop="loop"
                // autoplay="autoplay"
                muted="muted"
                controls
              >
                <source src="instructions.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div
            style={{
              clipPath: `polygon(0 100%, 100% 26%, 100% 100%, 0% 100%)`,
            }}
            className="bg-skygray  h-[200px] w-full  self-end -mb-[1px]"
          />
        </div>

        <Reviews />
        <UseCases />
        <Benefits />
        <Faqs />
      </div>
    </div>
  );
}

export default Landing2;
