import React from "react";
import { AiFillPlayCircle } from "react-icons/ai";

function PreviewCallComponent({ data }) {
  return (
    <div className="flex flex-col gap-4 ml-auto ">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl font-bold">{data?.title}</h1>
        <p className="text-gray-500 text-sm">{data?.desc}</p>
      </div>
      <div className="flex flex-row items-center gap-3 cursor-pointer mt-auto">
        <AiFillPlayCircle className="text-4xl" />
        <div className="flex flex-col gap-[2px]">
          <p className="text-gray-500 text-sm">{data?.buttonDesc}</p>
          <h1 className=" font-semibold">{data?.buttonTitle}</h1>
        </div>
      </div>
    </div>
  );
}

export default PreviewCallComponent;
