import React from "react";

function ReviewCard({ name, username, review, profile }) {
  return (
    <div className="w-full rounded-2xl  shadow-lg bg-white flex flex-col mx-4 md:mx-0 min-w-[350px] md:min-w-[10px] p-5 self-start">
      <div className="flex flex-row gap-3">
        <img
          className="w-12 h-12 rounded-full object-cover"
          src={profile}
          alt="profile"
        />
        <div className="flex flex-col">
          <h1 className="text-blue-900 font-bold ">{name}</h1>
          <p className="text-gray-500 text-sm font-light">{username}</p>
        </div>
      </div>

      <div className="whitespace-pre-line mt-6 text-gray-800 font-light text-sm">
        {`${review}`}
      </div>
    </div>
  );
}

export default ReviewCard;
