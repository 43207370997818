import React, { useEffect } from 'react'
import Landing from './screens/auth/Landing'
import { AuthNavigation } from './screens/navigation/authNavigation'
import { Toaster } from 'react-hot-toast'
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  doc,
} from "firebase/firestore";

import { auth, db } from './utils/firebase';
import useUser from './hooks/useUser';
import { AppNavigation } from './screens/navigation/appNavigation';
import { useNavigate } from "react-router-dom";
import Subscription from './screens/app/subscription';
import Landing2 from './screens/auth/Landing2';

function App() {

  const { user: currentUser, setUser } = useUser()
  console.log(currentUser)
  const navigate = useNavigate()



  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log(user)
        // setUser(user)
        if (user?.emailVerified) {
          navigate('/')
        }

      } else {
        setUser(null)
        // navigate('/')
      }
    });

  }, [])


  useEffect(() => {

    if (auth?.currentUser) {
      const unsub = onSnapshot(doc(db, "users", auth?.currentUser?.uid), (doc) => {
        console.log(" data: ", doc?.data());
        setUser({ id: doc?.id, ...doc?.data() })
      });
      return unsub
    }

  }, [auth?.currentUser]);




  console.log('currentUser',currentUser)

  return (

    

    currentUser && auth?.currentUser?.emailVerified ?
      <div className='mx-auto'>
        <Landing2 />
        {/* <Toaster />
       {currentUser?.subscription ? <AppNavigation />:<Subscription />} */}
      </div>
      :

      <div className='mx-auto'>
        <Landing2 />
        {/* <Toaster />
        <AuthNavigation /> */}
      </div>
  )
}

export default App