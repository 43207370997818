import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { MdOutlineDone } from "react-icons/md";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import { db , auth} from "../../utils/firebase";
import { signOut } from "firebase/auth";

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import useUser from "../../hooks/useUser";





const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// const options = {
//   mode: "payment",
//   amount: 1099,
//   currency: "usd",
//   // Fully customizable with appearance API.
//   appearance: {
//     /*...*/
//   },
// };


const CheckoutForm = () => {
  const stripe = useStripe();
  const {user}=useUser()
  const elements = useElements();
  const [selectedPlan, setSelectedPlan] = useState("mo");
  

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading]=useState(false)

  const [isCardComplete, setCardComplete] = useState(false);

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
  };

  const chargeSubscription=async(event)=>{
    event.preventDefault();

    if(loading)
    {
      return 
    }

    // console.log(elements?.getElement(CardElement))
    // return 
    const cardElement = elements.getElement('card');

    if (!cardElement ||  cardElement.empty || !isCardComplete) {
      // All required information is present
      // Use stripe and elements to handle payment
      // For example: stripe.createPaymentMethod()
      toast.error('Please enter valid card information.');
      return
    } 
    

    try {
      setLoading(true) 
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name:user?.displayName,
          email:user?.email,
        },
      });
      console.log('paymentMethod?.paymentMethod?.id,',paymentMethod?.paymentMethod?.id,)

      // call the backend to create subscription
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/charge_subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          customerId:user?.customer,
          name:user?.displayName,
          email:user?.email,
          // priceId:process.env.REACT_APP_PRODUCT_PRICE_ID
          priceId:process.env.REACT_APP_PRODUCT_PRICE_ID
        }),
      }).then((res) => res.json());

      


      if(response?.error)
      {
      
        toast.error(response?.error?.raw?.message)
        setLoading(false)
        return
      }

  // confirm the payment by the user
      const confirmPayment = await stripe?.confirmCardPayment(
        response.clientSecret
      );

      if (confirmPayment?.error) {
        // alert(confirmPayment.error.message);
        console.log(confirmPayment.error)
        toast.error(confirmPayment.error.message)
      } else {
        const userRef = doc(db, "users", auth?.currentUser?.uid);
        await setDoc(
          userRef,
          {
          
           subscription:response?.subscriptionId,
           subscriptionStatus:'active',
           plan:'basic'
          },
          { merge: true }
        );
        toast.success('Subscribed')
      }
   
      setLoading(false)
    } catch (error) {
      setLoading(false)
     console.log(error)
      if (error.code === 'card_declined') {
        if (error.decline_code === 'insufficient_funds') {
        
          toast.error("Your card was declined due to insufficient funds.")
        } else {
          // Handle other types of card declined errors
          // setError("Your card was declined.");
          toast.error("Your card was declined.")
        }
      }else{
        toast.error('Something went wrong')
      }

     
    }
  }

  const handleSubmit = async (event) => {

    event.preventDefault();

    if(loading)
    {
      console.log('--->>>','Loading....')
      return 
    }

    const cardElement = elements.getElement('card');
    if (!cardElement ||  cardElement.empty || !isCardComplete) {
      // All required information is present
      // Use stripe and elements to handle payment
      // For example: stripe.createPaymentMethod()
      toast.error('Please enter valid card information.');
      return
    } 

    try {
      setLoading(true)
      let paymentMethod=''
      try {
         paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: elements?.getElement(CardElement),
          billing_details: {
            name:user?.displayName,
            email:user?.email,
          },
        });
      } catch (error) {
        toast.error('Invalid Payment Method!')
        return
      }
      if(paymentMethod?.error)
      {
        toast.error(paymentMethod?.error?.message)
        setLoading(false)
        return
      }
      
      // call the backend to create subscription
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/create-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          name:user?.displayName,
          email:user?.email,
          priceId:process.env.REACT_APP_PRODUCT_PRICE_ID
        }),
      }).then((res) => res.json());

      console.log('------------->>>>>>Subscription',response?.subscriptionId)
      console.log('------------->>>>>>Customer',response?.customerId)

      const userRef = doc(db, "users", auth?.currentUser?.uid);
      if(response?.subscriptionId && response?.customerId)
      {
        await setDoc(
          userRef,
          {
           customer:response?.customerId,
           subscription:response?.subscriptionId,
           subscriptionStatus:'trialing',
           plan:'trial'
          },
          { merge: true }
        );
      }
      


      const statRef = doc(db, 'analytics', 'subscriptions');

      let stats=await getDoc(statRef)

      stats=stats?.data()
      let trials=stats.active_trials+1

      await setDoc(
        statRef,
        {
          active_trials:trials
        },
        { merge: true }
      );


      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
      toast.error('Something went wrong!')
    }
  };


  const CARD_ELEMENT_OPTIONS = {
    style: {
    base: {
   
    // color: '#31325F',
    fontWeight: 500,
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
    color: 'gray',
    },
    },
    invalid: {
    color: '#E25950',
    },
    },
    }

  const benefits = [
    "Endless Voice Creation",
    "Infinite Text Transcription",
    "Crisp, Clear Voice Output",
    "Multilingual Support",
    "User-Friendly Interface",
  ];

  const PlanComponent = ({ plan, price, title }) => {
    return (
      <div
        onClick={() => setSelectedPlan(plan)}
        className={`cursor-pointer w-full p-2 py-8  ${
          selectedPlan === plan
            ? "border-blue-900 border-[2px] bg-opacity-[0.7]"
            : "border-gray-400 border-[1px] bg-opacity-[0.4]"
        } bg-white  shadow-xl rounded-xl flex items-center justify-between duration-300`}
      >
        <div className="flex flex-row items-center gap-2">
          <div
            className={`w-5 h-5  rounded-full ${
              selectedPlan === plan
                ? "border-[2px] border-blue-900 "
                : "border-[1px] border-gray-400"
            }  flex items-center justify-center duration-300`}
          >
            <div
              className={`h-3 w-3 duration-200 rounded-full ${
                selectedPlan === plan
                  ? "bg-blue-900 scale-100"
                  : "bg-gray-500 scale-0"
              } `}
            />
          </div>

          <div
            className={`${
              selectedPlan === plan ? "text-blue-900" : "text-gray-500"
            } font-semibold`}
          >
            {title}
          </div>
        </div>

        <div
          className={`text-xl font-semibold ${
            selectedPlan === plan ? "text-blue-900" : "text-gray-500"
          }`}
        >{`$${price}/${plan}`}</div>
      </div>
    );
  };

  const logout = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSeQ6Phb4-HQy_jNacfHyhVq5BbFBO0PIJfXjnKWkdImoU_2nQ/viewform?embedded=true", '_blank');
    signOut(auth); 
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Show the modal when the user attempts to close the tab
   
     
      // Optionally, show a confirmation message
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures the effect runs only once during component mount

  return (
    <div className="w-full flex flex-col items-center">
      <div
      onClick={logout}
          className="cursor-pointer px-4 py-2 rounded-lg flex flex-row items-center gap-2 bg-rose-500 absolute right-5 top-3"
        >
          <h1 className="text-white font-bold">Log Out</h1>
        </div>

      <div className="w-[350px]  py-8 flex flex-col">
        <div className="w-full flex flex-col">
          <h1 className="font-bold text-3xl text-blue-900">
          Unlock Limitless Possibilities - Your Ultimate Speech Suite
          </h1>
          <div className="w-full h-[1px] bg-gray-300 my-4" />
          <div className="flex flex-col gap-2 mb-7">
            {benefits?.map((benefit) => {
              return (
                <div className="flex flex-row items-center gap-2">
                  <MdOutlineDone className="text-green-500 font-bold text-xl" />
                  <h1 className="text-sm text-blue-900">{benefit}</h1>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-2 mb-7">
            <PlanComponent title="Monthly" price={9.99} plan="mo" />
            {/* <PlanComponent title="Annual" price={300} plan="yr" /> */}
          </div>
        </div>

        <form onSubmit={user?.customer?chargeSubscription:handleSubmit} >
          <div className=" text-blue-900 font-semibold">
       Credit Card Payment
          </div>
          {/* <PaymentElement /> */}
          <CardElement 
          options={CARD_ELEMENT_OPTIONS}  
          onChange={handleCardChange}/>
          <button
            className="w-full mt-8 py-3 bg-[#6355FF]  rounded-lg  font-semibold text-white flex justify-center items-center"
            type="submit"
            disabled={!stripe || !elements}
          >
            {loading?
              
            <Oval
  visible={true}
  height="30"
  width="30"
  color="#fff"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  secondaryColor="transparent"
  />
            :`${user?.customer?"Start Subscription":"Start Your 7 Day Free Trial"}`}
          </button>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
        </form>

<div className="w-full h-5 my-5 text-blue-900 text-center">
By signing up, you agree to our <span onClick={()=>{ 
   const a = document.createElement('a')
   const url = 'https://firebasestorage.googleapis.com/v0/b/speechimo-54fc0.appspot.com/o/docs%2FSpeechimo_Terms_and_Conditions.pdf?alt=media&token=1353dbbb-8cf9-4082-98fa-4608da8d2842'
   a.href=url
   a.download = url.split('/').pop()
   a.target = '_blank';
   document.body.appendChild(a)
   a.click()
   document.body.removeChild(a)
}}   className="font-semibold text-blue-500 cursor-pointer">Terms and Conditions</span> and <span 
onClick={()=>{
  const a = document.createElement('a')
  const url = 'https://firebasestorage.googleapis.com/v0/b/speechimo-54fc0.appspot.com/o/docs%2FSpeechimo_Privacy_Policy.pdf?alt=media&token=cf58c366-55e0-452f-b12f-40ac9c858d38'
  a.href=url
  a.download = url.split('/').pop()
  a.target = '_blank';
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}}
 className="font-semibold text-blue-500 cursor-pointer">Privacy Policy</span>
</div>

<div className="my-5 mt-10 flex flex-col gap-2">
<h1 className="font-semibold text-blue-900 text-center">Enjoy full access for 7 days, absolutely free.</h1>
<p className="text-gray-500 text-sm text-center">
No charges will be applied now. After your 7-day free trial, your subscription will automatically begin, and the payment method provided will be charged. You can cancel anytime during the trial period to avoid charges.
</p>
</div>

      </div>
    </div>
  );
};



const Subscription = () => {
  return (
    <div
      className="
     
   flex w-full h-screen justify-center
 
  bg-gradient-to-br from-secondary  to-primary
    
  
    "
    >
      <div className="bg-white h-full w-full  flex bg-opacity-80  overflow-y-scroll  justify-center z-20">
        <Elements stripe={stripePromise} >
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default Subscription;
