import React from "react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";

function Navbar2() {
  return (
    <div className="flex flex-row items-center justify-between px-2 md:px-6">
      <img src="/logo.svg" className="w-40" alt="logo" />

      {/* <Link
        to="/login"
        className="bg-white px-4 py-2 rounded-lg flex flex-row items-center gap-2"
      >
        <FcGoogle className="text-xl" />
        <h1 className="text-secondary font-semibold hidden md:block">
          Continue with Google
        </h1>
        <h1 className="text-secondary font-semibold block md:hidden">
          Continue
        </h1>
      </Link> */}

      <div className="flex flex-row items-center gap-3">
       
      </div>
    </div>
  );
}

export default Navbar2;
