import React, { useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { BsHddStack } from "react-icons/bs";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import useUser from "../../hooks/useUser";
import { doc, updateDoc } from "firebase/firestore";
import { updatePassword } from "firebase/auth";
import { IoMenu } from "react-icons/io5";

import { auth, db } from "../../utils/firebase";
import toast from "react-hot-toast";
import useSidebar from "../../hooks/useSidebar";
import { FaCcVisa } from "react-icons/fa";
import { BsCreditCard2Back } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import BasicTable from "../../components/BasicTable";
import { MdDeleteOutline } from "react-icons/md";
import Invoices from "../../components/Invoices";
import { MdOutlineFileDownload } from "react-icons/md";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";

import { GoDownload } from "react-icons/go";
import { useSearchParams } from "react-router-dom";

const Billing = ({invoices , setInvoices}) => {
  const [isLoading, setIsLoading] = useState(false);
 
  const { user } = useUser()
  const data2 = [
    {
      name: "Nomi",
      email: "zawar@gmail.com",
      signupdate: "6/12/20",
      status: "paid",
      plan: "fantastic",
      delete: <MdOutlineFileDownload size={22} onClick={() => alert('Hello')} />,
    },

    // { name: 'John', email: "zawar@gmail.com", signupdate: "6/12/20", status: 24, plan: 4.0,delete:<MdDeleteOutline size={22}/> },
  ];


  const getInvoices = async () => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/get_invoices`, {
        customer: user?.customer
      })
      console.log('---->>>', response.data.invoices)
      setInvoices([...response.data.invoices.map(data => {
        console.log(data?.paid)


        const jsDate = new Date(data?.created * 1000); // Multiply by 1000 to convert seconds to milliseconds

        // Format the JavaScript Date using moment.js 
        const formattedDate = moment(jsDate).format('M/D/YY');
        console.log(formattedDate)
        return {
          name: formattedDate,
          email: `$${data?.amount_paid / 100}`,
          signupdate: formattedDate,
          status: data?.paid ? "paid" : 'unpaid',
          plan: "fantastic",
          pdfUrl: data?.invoice_pdf,
          delete: <GoDownload style={{color:'#6355FF'}} size={20} onClick={() => {

          }} />,
        }
      })])
    } catch (error) {
      console.log(error)
    }
  }

  const cancelSubscription = async () => {
    try {
      setIsLoading(true)
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/cancel_subscription`, {
        subscriptionId: user?.subscription,
        userId: user?.uid
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      toast.error('Something went wrong!')
    }
  }

  useEffect(() => {

    getInvoices()

  }, [])


  return (
    <>
      <div className=" grid grid-cols-1 md:grid-cols-2 space-x-2">
        {/* Left Side */}
        <div className="w-full p-2 mr-4">
          <h1 className="font-bold">Your Stan Subscription</h1>
          <div className="p-2 w-full mt-2 rounded-lg bg-skygray ">
            <div className="flex justify-between  pb-0">
              <p className="text-blue-900 font-semibold">Core Speech Plan</p>
              <p className="text-blue-800 font-semibold">$19.99/month</p>
            </div>
            <div className=" text-gray-400 ">Billed Monthly</div>
            <div className=" text-rose-400 mt-4">Please note that cancelling your subscription will result in immediate termination, and you will be redirected to our landing page.</div>
          </div>

          <div className="flex space-x-2 mt-4">
            <div onClick={() => cancelSubscription()} className="cursor-pointer bg-gradient-to-r from-primary to-secondary rounded-xl p-[2px] md:w-[200px] self-center">
              <div className="flex flex-nowrap h-full w-full bg-white hover:text-white hover:bg-gradient-to-r from-primary to-secondary rounded-xl p-2  justify-center items-center gap-2 shadow-2xl">
                {!isLoading ? (
                  <h1 className="font-semibold">Cancel Subscription</h1>
                ) : (
                  <Oval
                    height={18}
                    width={18}
                    color="#7C2AE8"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#00C4CC"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </div>
            </div>

            {/* <div className="cursor-pointer bg-gradient-to-r from-primary to-secondary rounded-xl p-[2px] md:w-[140px] self-center">
              <div className="flex flex-nowrap h-full w-full bg-white hover:text-white hover:bg-gradient-to-r from-primary to-secondary rounded-xl p-2 justify-center items-center gap-2 shadow-2xl">
                {!isLoading ? (
                  <h1 className="font-semibold">Change Plan</h1>
                ) : (
                  <Oval
                    height={15}
                    width={8}
                    color="#7C2AE8"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#00C4CC"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className="w-full h-64 p-2  ">
          <h1 className="font-bold">Payment Details</h1>
          <div className="h-24 w-full mt-2 rounded ">
            <div className="w-full h-12 flex justify-start items-center">
              <p className="text-xl pl-1">**** **** 986 </p>
            </div>
            <div className="w-full h-12 flex justify-between">
              <div className="w-1/2 h-full flex justify-start items-center border-r-2 border-gray-300 ">
                <p className="pl-1">MM/YY</p>
              </div>
              <div className="w-1/2 h-full flex justify-between items-center border-l-2 border-gray-300 ">
                <p className="text-xl pl-1">CVC</p>
                <BsCreditCard2Back className="text-4xl pr-1" />
              </div>
            </div>
          </div>
          <p className="pt-2 text-gray-400">Secure Payment Powered by Stripe</p>

          <div className="cursor-pointer bg-gradient-to-r from-primary to-secondary rounded-xl p-[2px] w-full md:w-[240px] self-center mt-2">
            <div className="flex flex-nowrap h-full w-full bg-white hover:text-white hover:bg-gradient-to-r from-primary to-secondary rounded-xl p-2 px-0 justify-center items-center gap-2 shadow-2xl">
              {!isLoading ? (
                <h1 className="font-semibold">Update</h1>
              ) : (
                <Oval
                  height={15}
                  width={15}
                  color="#7C2AE8"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#00C4CC"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="mt-6 bg-rose-500">
        <Invoices rows={invoices} />
      </div> */}
    </>
  );
};

const ProfileSection = ({ isGoogle }) => {
  const { user } = useUser();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.displayName,
      email: user?.email,
    },
  });

  const updateUser = async (data) => {
    try {
      const userRef = doc(db, "users", auth?.currentUser?.uid);

      // Set the "capital" field of the city 'DC'
      await updateDoc(userRef, {
        displayName: data?.name,
      });
      toast.success("Updated Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <h1 className="font-bold text-gray-800 mb-6 text-xl">My Profile</h1>

      <div className="flex flex-col w-full md:w-[70%] lg:w-[50%] max-w-[500px]">
        <h1 className="text-gray-800 font-semibold">Name</h1>
        <Input
          id="name"
          label="Name"
          required
          register={register}
          errors={errors}
          placeholder="Enter name here!"
          readonly={isGoogle ? "readonly" : false}
        />
      </div>

      <div className="flex flex-col w-full md:w-[70%] lg:w-[50%] max-w-[500px] mt-4">
        <h1 className="text-gray-800 font-semibold">Email</h1>
        <Input
          id="email"
          label="Email"
          register={register}
          errors={errors}
          placeholder="Enter name here!"
          readonly="readonly"
        />
      </div>

      {!isGoogle && (
        <div
          onClick={handleSubmit(updateUser)}
          className="px-6 py-2 max-w-[200px] rounded-xl mt-4 bg-[#6355FF] flex items-center justify-center cursor-pointer hover:translate-y-[2px] hover:scale-110 duration-200"
        >
          <h1 className="text-white font-semibold">Update</h1>
        </div>
      )}
    </div>
  );
};

const PasswordSection = () => {
  const { user } = useUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const onUpdate = (data) => {
    if (data?.password !== data?.confirm_password) {
      toast.error(`Password doesn't match`);
      return;
    }

    updatePassword(auth.currentUser, data?.confirm_password)
      .then(() => {
        // Update successful.
        reset();
        toast?.success("Password Updated!");
      })
      .catch((error) => {
        // An error ocurred
        // ...
        console.log(error);
        toast?.error("Something went wrong!");
      });
  };

  return (
    <div>
      <h1 className=" text-gray-800 mb-6 text-xl font-bold">Password</h1>

      <div className="flex flex-col w-full md:w-[70%] lg:w-[50%] max-w-[500px]">
        <h1 className="text-gray-800 font-semibold">New Password</h1>
        <Input
          id="password"
          label="New Password"
          required
          register={register}
          errors={errors}
          placeholder="New Password ..."
        />
      </div>

      <div className="flex flex-col w-full md:w-[70%] lg:w-[50%] max-w-[500px] mt-4">
        <h1 className="text-gray-800 font-semibold">Confirm New Password</h1>
        <Input
          id="confirm_password"
          label="Confirm New Password"
          required
          register={register}
          errors={errors}
          placeholder="Confirm New Password ..."
        />
      </div>

      <div
        onClick={handleSubmit(onUpdate)}
        className="px-6 py-2 max-w-[200px] rounded-xl mt-4 bg-[#6355FF] flex items-center justify-center cursor-pointer hover:translate-y-[2px] hover:scale-110 duration-200"
      >
        <h1 className="text-white font-semibold">Update</h1>
      </div>
    </div>
  );
};

function Settings() {
  
  const [searchParams] = useSearchParams();
const tab = searchParams.get('tab');
const [selectedTab, setSelectedTab] = useState(tab?tab:"Profile");



  const isGoogle =
    auth?.currentUser?.providerData[0]?.providerId === "google.com";

  const tabs = [
    {
      title: "Profile",
      Icon: () => (
        <IoPersonOutline
          className={` ${selectedTab === "Profile" ? "text-[#6355FF]" : "text-gray-900"
            }`}
        />
      ),
    },
    {
      title: "Billing",
      Icon: () => (
        <HiOutlineCurrencyDollar
          className={` ${selectedTab === "Billing" ? "text-[#6355FF]" : "text-gray-900"
            }`}
        />
      ),
    },
    // {
    //   title: "Payments",
    //   Icon: () => (
    //     <BsHddStack
    //       className={` ${
    //         selectedTab === "Payments" ? "text-[#6355FF]" : "text-gray-900"
    //       }`}
    //     />
    //   ),
    // },
  ];

  const Tab = ({ title, Icon }) => {
    return (
      <div
        className={`flex flex-row items-center gap-2 p-[6px] px-4 rounded-full border-[2px] cursor-pointer ${selectedTab === title ? "border-[#6355FF]" : "border-transparent"
          }`}
        onClick={() => setSelectedTab(title)}
      >
        <Icon />
        <h1
          className={`font-semibold  ${selectedTab === title ? "text-[#6355FF]" : "text-gray-900"
            }`}
        >
          {title}
        </h1>
      </div>
    );
  };

  const { menuHidden, setMenuHidden } = useSidebar();

  const [invoices, setInvoices] = useState([])

  return (
    <div className="w-full h-screen overflow-y-scroll  flex flex-col items-center ">
      <div className="w-full p-6  mb-4 border-b-[1px] border-b-skygray flex items-center gap-2">
        <div className="min-w-[32px] self-start">
          <IoMenu
            onClick={() => setMenuHidden(!menuHidden)}
            className="text-2xl font-bold text-[#00106D] block md:hidden self-start mt-[3px] "
          />
        </div>
        <div className="flex flex-col">
          <h1 className="text-xl font-bold text-gray-800">Account Settings</h1>
        </div>
      </div>
      <div className="w-[95%] md:w-[90%] flex flex-col my-4 ">
        <div className="flex flex-row items-center gap-3">
          {tabs.map((tab) => (
            <Tab title={tab?.title} Icon={tab?.Icon} />
          ))}
        </div>

        <div
          style={{
            boxShadow: " -1px 1px 11px -5px rgba(0,0,0,0.65)",
          }}
          className="w-full bg-white rounded-xl  mt-4 p-4 gap-7 flex flex-col"
        >
          {selectedTab === "Profile" ? (
            <>
              <ProfileSection isGoogle={isGoogle} />
              {!isGoogle && <PasswordSection />}
            </>
          ) : (
            <Billing invoices={invoices} setInvoices={setInvoices} />
          )}
        </div>

        {selectedTab !== "Profile"&&<div
          style={{
            boxShadow: " -1px 1px 11px -5px rgba(0,0,0,0.65)",
          }}
          className="w-full bg-white rounded-xl  mt-4 p-0 overflow-hidden gap-7 flex flex-col"
        > 
        <Invoices rows={invoices} />
        </div>}

      </div>
    </div>

  );
}

export default Settings;
