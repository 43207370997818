import React, { useEffect, useRef, useState } from "react";
import useAudios from "../../hooks/useAudios";
import { AiFillPlayCircle } from "react-icons/ai";
import { FaPauseCircle } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import { db, storage } from "../../utils/firebase";
import { collection, doc, deleteDoc , query , orderBy , onSnapshot, getDocs} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { IoMenu } from "react-icons/io5";
import useSidebar from "../../hooks/useSidebar";

const HistoryComponent = ({
  audio,
  setSelectedAudios,
  selectedAudios,
  handlePlay,
  handlePause,
  isPlaying,
  loadingAudio,
  currentAudio,
}) => {

  const handleChange = (e) => {
    console.log(e.target.checked, ":", JSON.parse(e.target.value));

    if (e.target.checked) {
      setSelectedAudios([...selectedAudios, JSON.parse(e.target.value)]);
    } else {
      setSelectedAudios([
        ...selectedAudios.filter(
          (aud) => aud?.id !== JSON.parse(e.target.value).id
        ),
      ]);
    }
  };

  return (
    <div className="flex flex-row items-center gap-1 bg-gray-100 p-2 py-4 ">
      <div className="w-[50px] p-1 flex items-center">
        <input
          type="checkbox"
          id={audio.id}
          value={JSON.stringify(audio)}
          checked={
            selectedAudios.filter((aud) => aud?.id === audio?.id).length > 0
          }
          onChange={handleChange}
          className="w-5 h-5 accent-slate-600"
        />
      </div>
      <div
        className="w-[150px] text-gray-600 p-1 font-semibold"
        onClick={() => {}}
      >
        {audio?.name}
      </div>
      <div className="w-[150px] text-gray-500 p-1 hidden md:block">
        {audio?.createdAt?.toDate().toDateString()}
      </div>
      {/* <div className="w-[150px] text-gray-800 p-1 ml-2">Created</div> */}
      <div className="w-full text-gray-800 p-1 flex flex-row justify-between ">
        <p className="mr-1">{audio?.text}</p>
        <div className="min-w-[32px] ">
          {currentAudio === audio?.id ? (
            <div onClick={handlePause}>
              {loadingAudio ? (
                <div className="bg-black w-8 h-8 rounded-full flex items-center justify-center">
                  <Oval
                    height={15}
                    width={15}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="gray"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              ) : (
                <FaPauseCircle className="text-3xl" />
              )}
            </div>
          ) : (
            <AiFillPlayCircle
              onClick={() => handlePlay(audio?.url, audio?.id)}
              className="text-3xl"
            />
          )}
        </div>
      </div>
    </div>
  );
};

function DashboardHistory() {
  const [checkAll, setCheckAll] = useState(false);

//   const { audios } = useAudios();
const [audios, setAudios] = useState([])

  const [selectedAudios, setSelectedAudios] = useState([]);

  const [currentAudio, setCurrentAudio] = useState("");
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { menuHidden, setMenuHidden } = useSidebar();

  const audioRef = useRef(new Audio());

  const handlePlay = (url, id) => {
    setCurrentAudio(id);
    audioRef?.current?.pause();
    audioRef.current.src = url;
    audioRef.current.play();
  };

  const handlePause = () => {
    setCurrentAudio("");
    audioRef.current.pause();
  };
  const handleLoad = () => {
    setLoadingAudio(false);
  };

  const handleLoadedData = () => {
    setLoadingAudio(false);
  };

  const handleCanPlay = () => {
    setLoadingAudio(false);
  };

  const handleWaiting = () => {
    setLoadingAudio(true);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setCurrentAudio("");
    // Do something when audio playback ends (e.g., update state)
    console.log("Audio playback finished.");
  };

  audioRef.current.addEventListener("load", handleLoad);
  audioRef.current.addEventListener("loadeddata", handleLoadedData);
  audioRef.current.addEventListener("canplay", handleCanPlay);
  audioRef.current.addEventListener("waiting", handleWaiting);
  audioRef.current.addEventListener("ended", handleEnded);

  const removeSelected = async () => {
    console.log("Remove", selectedAudios);
    if (selectedAudios.length < 1) {
      return;
    }
    try {
      selectedAudios.forEach(async (data) => {
        await deleteObject(
          ref(storage, `audios/${data.audioStorageReference}`)
        );
        await deleteDoc(doc(db, "audios", data.id));

        // Delete audio file from Firebase Storage using audioStorageReference

        console.log(
          `Document with ID ${data.id} and audio ${data.audioStorageReference} deleted.`
        );
      });

      toast.success("Removed Successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const downloadSelected = async () => {
    console.log("Download", selectedAudios);
    if (selectedAudios.length < 1) {
      return;
    }
    try {
      selectedAudios.forEach(async (audio) => {
        const response = await fetch(audio?.url);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", audio?.name); // Set the desired file name and extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

      toast.success("Downloaded Successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const getAudios=async()=>{
    const querySnapshot=await getDocs(collection(db, "audios"));
    
        const audiosTemp = [];
        querySnapshot.forEach((doc) => {
          
          audiosTemp.push({ id: doc.id, ...doc.data() });
        });
        console.log("Audios: ", audiosTemp);
        setAudios(audiosTemp);
        
      
  }
  

  useEffect(() => {
    // const q = query(
    //   collection(db, "audios"),
    //   orderBy("createdAt", "desc")
    // );
     getAudios()
  }, []);


  return (
    <div className="flex flex-col w-full  items-center py-0">
      <div className="w-full  mb-4   flex items-center gap-2">
        
        {/* <div className="flex flex-col">
          <h1 className="text-xl font-bold text-gray-800">Generated Files</h1>
          <div className="font-[500] mt-1  text-gray-400">
            Full list of all your generated samples, ready for download.
          </div>
        </div> */}
      </div>
      <div className="flex flex-col  w-full mb-4">
        {/* <div className="text-xl font-bold">Generated Files</div>
        <div className="font-[500] mt-1  text-gray-400">
          Full list of all your generated samples, ready for download.
        </div> */}

        <div className="flex flex-row items-center gap-4 my-4">
          <div
            className={` bg-gradient-to-r from-primary to-secondary rounded-xl p-[2px] w-[220px] mt-4 ${
              selectedAudios?.length > 0
                ? "opacity-100 cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
            // onClick={handleClick}
          >
            <div
              onClick={downloadSelected}
              className={`flex flex-nowrap h-full w-full bg-white  ${
                selectedAudios?.length > 0 &&
                "hover:bg-gradient-to-r hover:text-white"
              } from-primary to-secondary rounded-xl p-3 px-0 justify-center items-center gap-2 shadow-2xl`}
            >
              <h1 className="font-semibold">Download Selected</h1>
            </div>
          </div>

          {/* <div
            className={` bg-gradient-to-r from-primary to-secondary rounded-xl p-[2px] w-[220px] mt-4 ${
              selectedAudios?.length > 0
                ? "opacity-100 cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
            // onClick={handleClick}
          >
            <div
              onClick={removeSelected}
              className={`flex flex-nowrap h-full w-full bg-white  ${
                selectedAudios?.length > 0 &&
                "hover:bg-gradient-to-r hover:text-white"
              } from-primary to-secondary rounded-xl p-3 px-0 justify-center items-center gap-2 shadow-2xl`}
            >
              <h1 className="font-semibold">Remove Selected</h1>
            </div>
          </div> */}
        </div>

        <div className="w-full flex flex-col rounded-xl overflow-hidden">
          <div className="flex flex-row items-center gap-1 bg-gray-300 p-2 py-3 rounded-t-xl">
            <div className="w-[50px] font-semibold p-1 flex items-center gap-3">
              <input
                type="checkbox"
                id="vehicle1"
                value={"123"}
                checked={selectedAudios.length === audios.length}
                onChange={(e) => {
                  //   setCheckAll(e.target.checked);
                  if (e.target.checked) {
                    setSelectedAudios([...audios.map((e) => e)]);
                  } else {
                    setSelectedAudios([...[]]);
                  }
                }}
                className="w-5 h-5 accent-slate-600"
              />
            </div>
            <div
              className="w-[150px] font-semibold p-1"
              onClick={() => setCheckAll(!checkAll)}
            >
              Name
            </div>
            <div className="w-[150px] font-semibold p-1 hidden md:block">
              Date
            </div>
            {/* <div className="w-[150px] font-semibold p-1 ml-2">State</div> */}
            <div className="w-full font-semibold p-1">Text</div>
          </div>

          {audios.map((audio) => (
            <HistoryComponent
              audio={audio}
              setSelectedAudios={setSelectedAudios}
              selectedAudios={selectedAudios}
              handlePlay={handlePlay}
              handlePause={handlePause}
              loadingAudio={loadingAudio}
              isPlaying={isPlaying}
              currentAudio={currentAudio}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardHistory;
