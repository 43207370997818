import React, { useState } from "react";

import { BsChevronDown } from "react-icons/bs";

function Faqs() {
  const faqsData = {
    faqsHeading: "Frequently asked questions",
    questions: [
      {
        question:
          "What types of content can I enhance with this text-to-speech tool?",
        answer:
          "Our tool is versatile enough to support a wide range of content types, including but not limited to YouTube videos, TikTok clips, podcasts, audiobooks, and e-learning materials.",
      },
      {
        question: "How natural do the AI-generated voices sound?",
        answer:
          "We pride ourselves on the natural quality of our AI voices. They are designed to emulate human intonation and emotion closely, providing a listening experience that is both engaging and authentic.",
      },
      {
        question: "Can I customize the voice to match my brand or project?",
        answer:
          "At the moment, customization of the voice settings, such as pitch or speed, is not available. However, you can select from a range of voices to find one that fits your brand or project. We're working on adding more customization features in the near future.",
      },
      {
        question: "Is it possible to use this tool for different languages?",
        answer:
          "Yes, our text-to-speech tool automatically recognizes and supports multiple languages, making it effortless to cater to a global audience. Whether you're inputting text or using voice for speech-to-text, our AI seamlessly handles various languages.",
      },
      {
        question:
          "How do I get started with creating my own text-to-speech audio?",
        answer:
          "Getting started is simple. Just sign up for an account, type in or upload your text, choose your preferred voice, and our tool will generate the audio for you.",
      },
      {
        question:
          "Are there any limitations on the length of the text I can convert?",
        answer:
          "Our tool can handle both short and long-form text, but for the best performance and quality, we recommend dividing very lengthy texts into manageable sections.",
      },
      {
        question: "Can I use the generated audio commercially?",
        answer:
          "Yes, the audio produced by our tool can be used for commercial purposes, whether it's for audiobooks, commercial videos, or any other project.",
      },
      {
        question: "How quickly can I generate speech from my text?",
        answer:
          "Our text-to-speech conversion is fast and efficient, allowing you to produce audio files in just a few clicks, depending on the length of your text.",
      },
      {
        question:
          "Is there a way to test the tool before I commit to a subscription?",
        answer:
          "Sure, we offer a free trial period during which you can test all the features of our tool to see if it fits your needs.",
      },
      {
        question: "What kind of support do you offer if I run into issues?",
        answer:
          "We provide comprehensive support through our Help Center available on the platform. If you have any questions or need assistance, you can easily reach out to us there, and our customer service team will be eager to help you with timely and effective solutions.",
      },
    ],
  };
  const FaqCard = ({ question, index }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`p-0 cursor-pointer w-full mx-auto duration-500 bg-white  font-semibold max-h-[600px]  ${
          isOpen && "bg-white"
        }  ${
          index !== 0 && "border-t-[1px] border-t-gray-500"
        }bg-grey m-0 text-gray-900 py-5`}
      >
        <div className={"flex flex-row pr-2 justify-between items-center"}>
          <div className={`${isOpen && "text-blue"} text-light`}>
            {question.question}
          </div>
          <BsChevronDown
            onClick={() => setIsOpen(!isOpen)}
            className={`cursor-pointer text-gray-500 ${
              isOpen && "-rotate-180 text-blue"
            } duration-300 min-w-[23px]`}
            size={18}
          />
        </div>

        <div
          className={`flex duration-300 flex-col ${
            isOpen && "p-1"
          } font-normal  text-gray-600 overflow-scroll origin-top max-h-0  ${
            !isOpen ? "scale-y-0 opacity-0" : "max-h-[540px]"
          }`}
        >
          <div
            className={`${
              isOpen && "p-1"
            } bg-white rounded-md flex flex-row items-center justify-between mt-1`}
          >
            <div className=""> {question.answer}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-screen max-w-[100vw] overflow-hidden    px-4 md:px-8 lg:px-12 pb-32 py-4  pt-32 bg-white">
      <div className="md:w-[70%]  mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          {faqsData?.faqsHeading}
        </h1>
        {faqsData?.questions.map((question, index) => (
          <FaqCard question={question} index={index} />
        ))}
      </div>
    </div>
  );
}

export default Faqs;
