// store.js
import {create} from 'zustand';
import { persist } from 'zustand/middleware';

const useAdminStore = create(
  persist(
    (set) => ({
      admin_logged_in: false,
      setAdminLoggedIn: (value) => set({ admin_logged_in: value }),
    }),
    {
      name: 'admin-storage', // Key for the storage
      getStorage: () => localStorage, // Use localStorage for persistent storage
    }
  )
);

export default useAdminStore;
