import React from "react";

import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Benefits() {
  const navigate = useNavigate();
  const benefitsData = {
    title: "Save Time and Money!",
    desc: `Tired of shelling out big bucks for expensive voice-over artists? Say
        goodbye to those hefty bills and hello to the future of voice
        creation! Our Text-to-Speech tool is here to revolutionize your
        content creation process, while saving you both time and money.`,
    trialPortion: {
      heading: `Ready to revolutionize your content creation?`,
      desc: `It's time to take the leap into a world where saving money and creating lifelike human voices is just a click away. Say goodbye to the pain of expensive voice-over artists and embrace the future of content creation. Try our Text-to-Speech tool today!`,
      heading2: ``,
      buttonTitle: `Start My Trial`,
    },

    benefits: [
      {
        title: "💰 Save Big:",
        desc: `Imagine the savings when you no longer need to hire
        expensive voice-over artists for your videos, presentations,
        podcasts, and more. Our tool provides professional-grade voices
        at a fraction of the cost, so your budget stays intact.`,
        image: `/images/voice_over.svg`,
        right: false,
      },
      {
        title: "⏰ Save Time:",
        desc: `Forget about time-consuming casting, recording sessions,
        and endless revisions. With our tool, you can generate
        high-quality voiceovers in seconds. No more waiting
        for voice artists to deliver, create your content on your schedule.`,
        image: `/images/save_time.svg`,
        right: true,
      },
      {
        title: "🗨️ Realistic Human Voices:",
        desc: `Our cutting-edge technology generates the most lifelike human
        voices that will captivate your audience. From engaging narrations
        to persuasive sales pitches, your content will sound as if it's
        delivered by a real human.`,
        image: `/images/user.svg`,
        right: false,
      },
      {
        title: "🌟 Versatile & Efficient:",
        desc: `Whether you're creating marketing videos, e-learning courses,
        or podcasts, our Text-to-Speech tool is your all-in-one solution.
        It adapts to various contexts, making your content creation
        process smoother and more efficient.`,
        image: `/images/youtube.svg`,
        right: true,
      },
      {
        title: "🚀 Boost Productivity:",
        desc: `Free up your team's valuable time to focus on creativity
        and strategy, rather than spending hours on voice-over logistics.
        Get more done in less time, with the assurance of
        exceptional results.`,
        image: `/images/productivity.svg`,
        right: false,
      },
    ],
  };

  const leftFeaturesVariants = {
    initial: {
      opacity: 0,
      x: -200,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };
  const rightFeaturesVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className=" flex flex-col items-center py-24">
      <div className="flex flex-col items-center px-1">
        <h1 className="text-gray-800 text-3xl font-semibold text-center">
          {benefitsData?.title}
        </h1>
        <p className="text-gray-800 mt-4 text-lg text-center mx-4 md:mx-12 lg:mx-[20%]">
          {benefitsData?.desc}
        </p>
      </div>
      <div className="flex flex-col mt-10 gap-8">
        {benefitsData?.benefits?.map((benefit) => {
          return (
            <div className="w-screen   md:h-[300px] my-8 flex flex-col md:flex-row justify-between gap-2 md:gap-8">
              <motion.div
                variants={
                  benefit.right ? rightFeaturesVariants : leftFeaturesVariants
                }
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                className={`flex ${
                  benefit.right
                    ? "md:order-2 rounded-l-full self-end"
                    : "rounded-r-full self-start"
                } md:flex-[1] lg:flex-1 h-48 md:h-[250px] lg:h-[300px] w-[80%]  bg-white   
                   
                 relative`}
              >
                <img
                  src={benefit?.image}
                  alt="benefit"
                  className={`h-[200px] md:h-[250px] lg:h-[300px] absolute ${
                    benefit?.right ? "left-6" : "right-10"
                  } -top-7 md:-top-10`}
                />
              </motion.div>

              <motion.div
                className={`flex md:flex-1 ${
                  benefit?.right && "ml-4 lg:ml-12 xl:ml-28"
                } p-6 lg:-mt-0 md:pr-[5%] lg:pr-[12%] flex-col w-full   h-56   justify-center `}
              >
                <h1 className="font-bold text-2xl text-gray-800 mb-5">
                  {benefit?.title}
                </h1>
                <p className="text-gray-800 pl-2 font-medium">
                  {benefit?.desc}
                </p>
              </motion.div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col items-center px-1 mt-8">
        <h1 className="text-gray-800  text-3xl font-semibold text-center">
          {benefitsData?.trialPortion?.heading}
        </h1>
        <p className="text-gray-800 mt-3 font-medium  text-center mx-4 md:mx-[15%] lg:mx-[27%]">
          {benefitsData?.trialPortion?.desc}
        </p>
      </div>

      <div className="flex flex-col items-center px-1 mt-16 gap-1">
        <h1 className="text-3xl font-semibold text-center">
          {benefitsData?.trialPortion?.heading2}
        </h1>

        {/* <div
          onClick={() => navigate("/signup")}
          style={{
            boxShadow: " -1px 1px 11px -5px rgba(0,0,0,0.65)",
          }}
          className="cursor-pointer bg-secondary group mt-4 py-7 px-10 rounded-full  flex flex-row items-center justify-between hover:scale-[1.05] duration-200"
        >
          <h1 className="text-white text-xl font-bold">
            {benefitsData?.trialPortion?.buttonTitle}
          </h1>
        </div> */}
      </div>
    </div>
  );
}

export default Benefits;
